
// export const serverUrl = "http://localhost:9000";
export const serverUrl = "/api";

export const getToken = () => localStorage.getItem('token');

export const setToken = token => localStorage.setItem('token', token);

export const dropToken = () => localStorage.removeItem('token');

export const tokenExists = () => getToken() != null;

export function postConfig() {
    return {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        }
    }
}

export function postAuthorizedConfig() {
    return {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${getToken()}`
        }
    }
}

export function getConfig() {
    return {
        method: "GET",
        headers: {
            authorization: `Bearer ${getToken()}`
        }
    }
}