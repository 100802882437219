<template>
    <div class="loader"></div>
</template>

<style scoped>
.loader {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: var(--bs-primary);
    position: relative;
}

.loader:before,
.loader:after {
    content: "";
    position: absolute;
    border-radius: 50%;
    inset: 0;
    background: var(--bs-body-color);
    transform: rotate(0deg) translate(30px);
    animation: rotate 1s ease infinite;
}

.loader:after {
    animation-delay: 0.5s
}

@keyframes rotate {
    100% {
        transform: rotate(360deg) translate(30px);
    }
}
</style>

<script>
export default {
    name: "LoadingSpinner",
    mounted() {
    }
}
</script>