import { defineStore } from "pinia";
import { computed } from 'vue';
import { useDocumentStore } from '@/stores/documents';
import { usePeriodicsStore } from '@/stores/periodics';
import { useInvoicesStore } from '@/stores/invoices';


export const useListingStore = defineStore("listing-store", () => {

    const documentStore = useDocumentStore();
    const periodicStore = usePeriodicsStore();
    const invoicesStore = useInvoicesStore();

    const final = computed(() => {
        return Array.prototype.concat(documentStore.all, periodicStore.all, invoicesStore.all);
    })

    function dropAll() {
        documentStore.dropAll()
        periodicStore.dropAll()
        invoicesStore.dropAll()
    }

    function fetchAll() {
        invoicesStore.reload();
        periodicStore.reload();
        documentStore.reload();
    }

    const loading = computed(() => {
        return documentStore.loading
            || periodicStore.loading
            || invoicesStore.loading;
    })

    const showLoader = computed(() => {
        return loading.value;
    });

    const error = computed(() => invoicesStore.error);

    return { entries: final, dropAll, loading, reload: fetchAll, showLoader, error };

})
