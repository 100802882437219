import { useSelectedDateStore } from '@/stores/date'
import { defineStore, storeToRefs } from "pinia";
import { ref, watch, computed } from "vue";
import { deleteDocument, getDocuments, updateDocument, uploadDocument } from '@/rest/document';


export const useDocumentStore = defineStore("documents", () => {
    const { month, year } = storeToRefs(useSelectedDateStore());

    const documents = ref([]);
    const loading = ref(false);

    const all = computed(() => {
        return documents.value.map((d, index) => {
            return { type: d.isScan ? 'scan' : 'document', storeId: index, ...d };
        })
    });


    async function fetchAll() {
        loading.value = true;
        documents.value = [];
        const res = await getDocuments(year.value, month.value);
        if (typeof res === "number" || !res) {
            return false;
        }
        documents.value = await res;
        loading.value = false;
        return true;
    }
    watch(ref([month, year]), fetchAll, { deep: true });


    async function updateOne(id, name, checkout, isPaid) {
        const status = await updateDocument(id, name, checkout, isPaid);
        if (status === 200) {
            const index = documents.value.findIndex(d => d.id === id);

            documents.value[index].name = name;
            documents.value[index].checkout = checkout;
            documents.value[index].isPaid = isPaid;

            return true;
        }
        return false;
    }

    async function deleteOne(id) {
        const status = await deleteDocument(id);
        if (status === 200) {
            const index = documents.value.findIndex(d => d.id === id);
            documents.value.splice(index, 1);
            return true;
        }
        return false;
    }

    async function addOne(formData) {
        const res = await uploadDocument(formData);
        const text = await res.text();
        const data = JSON.parse(text);

        if (res.status === 200) {
            documents.value.push(data);
        }

        return { status: res.status, data };
    }

    function dropAll() {
        documents.value = [];
    }

    return { all, updateOne, deleteOne, addOne, dropAll, loading, reload: fetchAll };

})

