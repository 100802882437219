<template>
    <div class="bg-body-tertiary p-3 rounded mt-2">
        <div class="d-flex justify-content-between">
            <div class="d-flex expand-listing-item-trigger" data-bs-toggle="collapse" :data-bs-target="`#${this.uuid}`"
                aria-expanded="false" :aria-controls="this.uuid">
                <div class="pe-3">
                    <div>
                        <span class="material-symbols-outlined" :class="associatedData.isScan === true ? 'c3' : 'c4'">
                            {{ associatedData.isScan === true ? "scan" : "draft" }}
                        </span>
                    </div>
                    <div><span class="material-symbols-outlined text-secondary">expand_more</span></div>
                </div>
                <div>
                    <div class="mb-1">{{ this.name }}</div>
                    <small class="text-secondary ">{{ this.associatedData.date }}</small>
                </div>
            </div>

            <div class="d-flex align-items-center">
                <div class="mb-0 d-flex flex-row d-flex align-items-center">
                    <div class="pe-2">{{ this.checkout }}</div>
                    <button type="button" class="btn btn-sm d-flex align-items-center rounded-pill" @click="togglePayment">
                        <span v-if="isPaid" class="material-symbols-outlined text-success">paid</span>
                        <span v-if="!isPaid" class="material-symbols-outlined text-primary">send_money</span>
                    </button>
                    <button type="button" class="btn btn-sm d-flex align-items-center rounded-pill ms-2"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        <span class="material-symbols-outlined">more_vert</span>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end">
                        <li @click="deleteMe"><a class="dropdown-item">Usuń</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="collapse" :id="this.uuid">

            <div class="col-sm-12 mt-3 mb-3">
                <PayButton :isPaid="isPaid" @click="togglePayment()"></PayButton>
            </div>

            <div class="col-12 mb-3">
                <AttachmentButton :fileInfo="this.associatedData.file"
                    :canRecieveFile="this.associatedData.canRecieveFile" />
            </div>

            <p class="fs-5 text-secondary">Informacje nt. dokumentu</p>

            <form class="row g-3">

                <div class="col-12">
                    <label for="companyName" class="form-label">Nazwa dokumentu</label>
                    <input type="text" class="form-control" id="companyName" placeholder="Document 1" v-model="this.name">
                </div>

                <div class="col-12">
                    <label for="typeNumber" class="form-label">Kwota</label>
                    <input type="number" class="form-control" id="typeNumber" placeholder="" v-model="this.checkout"
                        @focusout="checkout = parseFloat(checkout).toFixed(2).toString()">
                </div>

                <div class="d-flex justify-content-between align-items-center">
                    <div></div>
                    <div class="d-flex flex-row">
                        <a>
                            <button type="button" class="btn btn-primary btn-sm" @click="save" :disabled="isButtonDisabled">
                                Zapisz zmiany
                            </button>
                        </a>
                    </div>
                </div>

            </form>

        </div>
    </div>
</template>

<script>
import { FILE_BRIDGE_MIXIN } from '@/mixins/bridges/files';
import PayButton from './payButton.vue';
import AttachmentButton from './attachmentButton.vue';
import { useDocumentStore } from '@/stores/documents';

export default {
    name: 'UploadListingItem',
    mixins: [FILE_BRIDGE_MIXIN],
    props: {
        uuid: Number,
        associatedData: Object
    },
    setup() {
        const documents = useDocumentStore();
        return { documents };
    },
    data() {
        return {
            name: this.associatedData.name,
            checkout: parseFloat(this.associatedData.checkout).toFixed(2),
            isPaid: this.associatedData.isPaid,
        };
    },
    methods: {
        async save() {
            const success = await this.documents.updateOne(this.associatedData.id, this.name, this.checkout, this.isPaid);
            if (!success) {
                this.pushError("Nieznany błąd");
                return;
            }
        },
        async deleteMe() {
            const success = await this.documents.deleteOne(this.associatedData.id);
            if (!success) {
                this.pushError("Usuwanie dokumentu nie powiodło się");
                return;
            }
        },
        togglePayment() {
            this.isPaid = !this.isPaid;
            this.save();
        },
    },
    computed: {
        isButtonDisabled() {
            const value = this.checkout !== this.associatedData.checkout ||
                this.name !== this.associatedData.name;
            return !value;
        }
    },
    components: { PayButton, AttachmentButton }
}
</script>