import { serverUrl, postConfig } from "@/rest/token";

export const PASSWORD_RESET_MIXIN = {

    methods: {

        async requestPasswordReset(username) {
            return await fetch(`${serverUrl}/auth/reset-password-request`, {
                ...postConfig(),
                body: JSON.stringify({ username, })
            });
        },

        async resetPassword(code, username, password) {
            return await fetch(`${serverUrl}/auth/reset-password`, {
                ...postConfig(),
                body: JSON.stringify({ username, password, code })
            });
        }

    }
}