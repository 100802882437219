<template>
    <div class="toast-container position-fixed top-0 p-3 z-6 start-50 translate-middle-x">
        <component v-for="(item, index) in GLOBALS.uiMessages" :key="item.uuid" :id="item.uuid" :dump="index"
            :messageData="item" :is="getEntryComponentType(item)">
        </component>
    </div>
</template>


<script>
import ErrorMessage from './errorMessage.vue';
import InfoMessage from './infoMessage.vue';

export default {
    name: "UiMessageStack",
    inject: ['GLOBALS'],
    components: { ErrorMessage, InfoMessage },

    methods: {
        getEntryComponentType(item) {

            if (item.type === 'error')
                return "ErrorMessage";

            if (item.type === 'info')
                return "InfoMessage";

            return "";

        }
    }
}
</script>