import { defineStore } from "pinia";
import { useLocalStorage } from "@vueuse/core";
export const useUserDataStore = defineStore("user", {

    state: () => {
        return useLocalStorage("userData", {
            send_to_mail: "",
            user_name: "",
            logo_base64: "",
            isAdmin: false,
            storage_limit_mb: 0
        });
    },
    actions: {
        onLogout() {
            this.send_to_mail = null;
            this.user_name = null;
            this.logo_base64 = "";
            this.isAdmin = false;
            this.storage_limit_mb = 0;
        }
    }
});