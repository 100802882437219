<template>
    <div class="toast show" role="alert" aria-live="assertive" aria-atomic="true" ref="containerDiv">
        <div class="toast-header text-danger">
            <span class="material-symbols-outlined me-2">error</span>
            <strong class="me-auto">{{ messageData.title }}</strong>
            <!-- <small class="text-muted">just now</small> -->
            <button type="button" class="btn-close" aria-label="Close" @click="close"></button>
        </div>
        <div class="toast-body">
            {{ messageData.text }}
        </div>
    </div>
</template>


<script>
import { Toast } from 'bootstrap';

export default {
    name: "ErrorMessageToast",
    props: {
        messageData: Object,
        id: String
    },
    data() {
        return {
            bsToast: null
        }
    },
    mounted() {
        const properties = {
            autohide: false
        };

        if (this.messageData.delay !== 0) {
            properties["delay"] = this.messageData.delay;
            properties.autohide = true;
        }
        this.$refs.containerDiv.addEventListener('hidden.bs.toast', () => {
            this.pop(this.id);
        });

        this.bsToast = new Toast(this.$refs.containerDiv, properties);
        this.bsToast.show();
    },
    methods: {
        close() {
            this.bsToast.hide("slow");
        }
    }

}
</script>