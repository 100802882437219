<template>
    <div class="bg-body-tertiary p-3 rounded mt-2">
        <div class="d-flex justify-content-between">
            <div class="d-flex expand-listing-item-trigger" @click="this.isFormShown = !this.isFormShown"
                :data-bs-target="`#${this.uuid}`" aria-expanded="false" :aria-controls="this.uuid"
                data-bs-toggle="collapse">
                <div class="pe-3">
                    <div><span class="material-symbols-outlined c2">avg_pace</span></div>
                    <div>
                        <span class="material-symbols-outlined text-secondary">expand_more</span>
                    </div>
                </div>
                <div>
                    <div class="mb-1">{{ name }}</div>
                    <small class="text-secondary">{{ date }}</small>
                </div>
            </div>

            <div class="d-flex align-items-center">
                <div class="mb-0 d-flex flex-row d-flex align-items-center">
                    <div class="pe-2">{{ checkout }}</div>
                    <button type="button" class="btn btn-sm d-flex align-items-center rounded-pill" @click="togglePayment">
                        <span v-if="isPaid" class="material-symbols-outlined text-success">paid</span>
                        <span v-if="!isPaid" class="material-symbols-outlined text-primary">send_money</span>
                    </button>
                    <button type="button" class="btn btn-sm d-flex align-items-center rounded-pill ms-2"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        <span class="material-symbols-outlined">more_vert</span>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end">
                        <li @click="deleteMe('all')"><a class="dropdown-item">Usuń wszystkie</a></li>

                        <li v-if="isInstance" @click="deleteMe('singleInstance')"><a class="dropdown-item">Usuń tą
                                płatność</a></li>
                        <li v-else @click="deleteMe('single')"><a class="dropdown-item">Usuń tą płatność</a></li>

                        <li v-if="isInstance" @click="deleteMe('thisAndFollowingInstance')"><a class="dropdown-item">Usuń tą
                                płatność i kolejne</a></li>
                        <li v-else @click="deleteMe('thisAndFollowing')"><a class="dropdown-item">Usuń tą płatność i
                                kolejne</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="collapse" :id="this.uuid">

            <div class="col-sm-12 mt-3 mb-3">
                <PayButton :isPaid="isPaid" @click="togglePayment"></PayButton>
            </div>

            <p class="fs-5 text-secondary">Ustawienie płatności</p>


            <div class="col-12 mb-3">
                <AttachmentButton :fileInfo="this.file" ref="attBtn" :canDeleteFiles="true"
                    @fileStatusChange="val => fileSelected = val" :canRecieveFile="this.canRecieveFile"
                    @delete-file="deleteFile">
                </AttachmentButton>
            </div>

            <form class="row g-3">

                <div class="col-12">
                    <label for="companyName" class="form-label">Nazwa płatności</label>
                    <input type="text" class="form-control" id="companyName" placeholder="Zus" v-model="name">
                </div>
                <!-- 
                <div class="col-12">
                    <label for="companyName" class="form-label">Cykl płatności od dnia</label>
                    <input id="startDate" class="form-control" type="date" v-model="starts" />
                    <span id="startDateSelected"></span>
                </div>

                <div class="col-12">
                    <select class="form-select" aria-label="Default select example" v-model="repeats">
                        <option selected value="1">Codziennie</option>
                        <option value="2">Co tydzień w:X</option>
                        <option value="3">Co miesiąc w:X</option>
                        <option value="4">Co rok w dniu:X</option>
                    </select>
                </div> -->

                <ContractorPicker :id="contractorId" @contractor-changed="contractorPicked" v-if="this.isFormShown"
                    @message-adding-contractor="openedModal" ref="picker">
                    <!-- @message-adding-contractor="$emit('message-adding-contractor', { uuid: this.associatedData.uuid, serverid: this.associatedData.id })" -->
                </ContractorPicker>
                <div class="col-md-6">
                    <label for="inputAddress" class="form-label">Address</label>
                    <div class="input-group">
                        <input type="text" class="form-control" id="inputAddress" disabled="true"
                            :value="this.contractor.address1">
                        <button class="btn btn-outline-secondary rounded-end d-flex align-items-center" type="button"
                            @click="copy(this.contractor.address1)" id="button-addon2"><span
                                class="material-symbols-outlined">content_copy</span></button>
                    </div>
                </div>
                <div class="col-md-6">
                    <label for="inputAddress" class="form-label">Address</label>
                    <div class="input-group">
                        <input type="text" class="form-control" id="inputCity" placeholder="" disabled="true"
                            :value="this.contractor.address2">
                        <button class="btn btn-outline-secondary rounded-end d-flex align-items-center" type="button"
                            @click="copy(this.contractor.address2)" id="button-addon2"><span
                                class="material-symbols-outlined">content_copy</span></button>
                    </div>
                </div>
                <div class="col-md-6">
                    <label for="kwota" class="form-label">Numer rachunku dostawcy</label>
                    <div class="input-group">
                        <input type="text" class="form-control" id="Numerrachunku" disabled="true"
                            :value="this.contractor.bankAccountNumber" v-mask="'##-####-####-####-####-####-####'">
                        <button class="btn btn-outline-secondary rounded-end d-flex align-items-center" type="button"
                            @click="copy(this.contractor.bankAccountNumber.replaceAll('-', ''))" id="button-addon2"><span
                                class="material-symbols-outlined">content_copy</span></button>
                    </div>
                </div>
                <div class="col-md-6">
                    <label for="nip" class="form-label">NIP</label>
                    <div class="input-group">
                        <input type="text" class="form-control" id="nip" placeholder="" disabled="true"
                            :value="this.contractor.nip">
                        <button class="btn btn-outline-secondary rounded-end d-flex align-items-center" type="button"
                            @click="copy(this.contractor.nip.replaceAll('-', ''))" id="button-addon2"><span
                                class="material-symbols-outlined">content_copy</span></button>
                    </div>
                </div>
                <div class="col-12">
                    <label for="companyName" class="form-label">Opis płatności</label>
                    <div class="input-group">
                        <input type="text" class="form-control" id="opisplatnosci" placeholder="" v-model="description">
                        <button class="btn btn-outline-secondary rounded-end d-flex align-items-center" type="button"
                            @click="copy(this.description)" id="button-addon2"><span
                                class="material-symbols-outlined">content_copy</span></button>
                    </div>
                </div>
                <div class="col-md-6">
                    <label for="inputCity" class="form-label">Kwota</label>
                    <div class="input-group">
                        <input type="number" class="form-control" id="kwota" v-model="checkout"
                            @focusout="checkout = parseFloat(checkout).toFixed(2).toString()">
                        <button class="btn btn-outline-secondary rounded-end d-flex align-items-center" type="button"
                            @click="copy(this.checkout)" id="button-addon2"><span
                                class="material-symbols-outlined">content_copy</span></button>
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                    <div></div>
                    <div class="d-flex flex-row">
                        <a>
                            <button type="button" class="btn btn-primary btn-sm" @click="saveBtn"
                                :disabled="isButtonDisabled">
                                Zapisz zmiany
                            </button>
                        </a>
                    </div>
                </div>
            </form>

        </div>
    </div>
</template>

<script>

import { FILE_BRIDGE_MIXIN } from '@/mixins/bridges/files';
import ContractorPicker from "./contractorPicker.vue";
import PayButton from './payButton.vue';
import AttachmentButton from './attachmentButton.vue';
import { usePeriodicsStore } from '@/stores/periodics';
import { watch, ref } from 'vue';
import { useContractorsStore } from '@/stores/contractors';

export default {
    name: 'PeriodicalListingItem',
    mixins: [FILE_BRIDGE_MIXIN],
    components: { ContractorPicker, PayButton, AttachmentButton },
    props: {
        uuid: Number,
        associatedData: Object,
        newlyAddedContractor: Object,
        storeId: Number
    },
    setup(props) {
        const periodics = usePeriodicsStore();
        const contractors = useContractorsStore();

        const name = ref();
        const isPaid = ref();
        const checkout = ref();
        const description = ref();
        const contractorId = ref();
        const file = ref(null);
        const canRecieveFile = ref();

        watch(() => periodics.all[props.storeId], () => {
            const item = periodics.all[props.storeId];
            if (item) {
                name.value = item.name;
                isPaid.value = item.isPaid;
                checkout.value = item.checkout.toFixed(2);
                description.value = item.description || '';
                contractorId.value = item.contractor.id;
                file.value = item.file || null;
                canRecieveFile.value = item.canRecieveFile;
            }

        }, { immediate: true, deep: true });

        return { periodics, name, isPaid, checkout, description, contractorId, canRecieveFile, file, contractors }
    },
    data() {
        return {
            isFormShown: false,
            fileSelected: false,
            modalWasActivated: false,
            contractor: {
                address1: '',
                address2: '',
                bankAccountNumber: '',
                email: '',
                name: '',
                nip: ''
            },
        }
    },
    computed: {
        date: {
            cache: false,
            get: function () {
                const dataObj = new Date(this.associatedData.date);
                const day = dataObj.getDate();
                const dayStr = day < 10 ? `0${day}` : day;
                const month = dataObj.getUTCMonth() + 1;
                const monethStr = month < 10 ? `0${month}` : month;

                return `${dataObj.getUTCFullYear()}-${monethStr}-${dayStr}`
            }
        },
        isInstance() {
            return this.associatedData.category === "instance";
        },
        isButtonDisabled() {
            return (!this.updateGeneric && !this.needsToUpdateInstance);
        },
        updateGeneric() {
            const val =
                parseFloat(this.checkout, 10) !== this.associatedData.checkout ||
                this.name !== this.associatedData.name;
            return val;
        },
        needsToUpdateInstance() {

            const val =
                this.isPaid !== this.associatedData.isPaid
                || this.description !== (this.associatedData.description || '')
                || this.fileSelected;

            return val;
        },
    },
    methods: {
        async togglePayment() {
            this.isPaid = !this.isPaid;
            await this.saveInstance();
        },

        openedModal() {
            this.modalWasActivated = true;
            sessionStorage.setItem('contractorCalledFrom', 'periodicalEntry');
        },

        async fetchContractor(id) {
            const contractor = await this.contractors.getById(id);
            for (const key in contractor) {
                this.contractor[key] = contractor[key];
            }
        },
        async contractorPicked(pickedContractor) {
            this.contractorId = pickedContractor.id;
            await this.fetchContractor(pickedContractor.id);
            await this.saveCommon();
        },
        async saveInstance() {
            const updateData = new FormData(this.$refs.attBtn.getFileFormRef());
            updateData.append("parentId", this.associatedData.id);
            updateData.append("date", this.associatedData.date);
            updateData.append("isPaid", this.isPaid);
            if (this.description) {
                updateData.append("description", this.description);
            }

            const resp = await this.periodics.updateOneInstance(updateData);

            if (resp.status == 400) {
                const respData = JSON.parse(await resp.text());
                if (respData.errorcode == 604) {
                    this.pushError("Limit miejsca na załączniki został osiągnięty, wykonaj archiwizację");
                    return;
                }
            }
            if (resp.status == 413) {
                this.pushError("Załącznik przekracza limit 16MB");
                return;
            }
            if (resp.status != 200) {
                this.pushError("Nie udało się zaktualizować danych płatności okresowej")
            }
        },
        async saveCommon() {
            const checkout = parseFloat(this.checkout, 10);
            const status = await this.periodics.updateOne(this.associatedData.id, this.contractorId, this.name, checkout);
            if (status != 200) {
                this.pushError("Nie udało się zaktualizować danych płatności okresowej")
            }
        },
        async saveBtn() {

            if (this.updateGeneric) {
                await this.saveCommon();
            }

            if (this.needsToUpdateInstance) {
                await this.saveInstance();
            }

        },
        async deleteMe(type) {
            const respStatus = await this.periodics.deleteOne(this.associatedData.id, this.associatedData.date, type);
            if (respStatus != 200) {
                this.pushError("Nieznany błąd");
                return;
            }
        },
        async deleteFile() {
            if (! await this.periodics.detachFile(this.associatedData.id, this.associatedData.date)) {
                this.pushError("Nie udalo sie nie udało się usunąc pliku ");
            }
            else {
                this.$refs.attBtn.clear();
            }
        }

    },
    watch: {
        contractorId() {
            this.fetchContractor(this.contractorId);
        },
        isFormShown() {
            if (this.isFormShown) {
                this.fetchContractor(this.associatedData.contractor.id);
            }
        },
        async newlyAddedContractor(data) {

            if (data && this.modalWasActivated) {
                await this.fetchContractor(data.id);
                this.contractorId = data.id;
                await this.saveCommon();
            }

        }
    }
}
</script>