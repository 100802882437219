import { serverUrl, getConfig, getToken, postAuthorizedConfig } from "@/rest/token";

export async function getContractors(confirmedOnly) {
    let response;
    try {
        response = await fetch(`${serverUrl}/contractors/?confirmed-only=${confirmedOnly}`, getConfig());
        if (response.status == 200) {
            const text = await response.text();
            return { status: response.status, data: JSON.parse(text) };
        }

    } catch (error) {
        return { status: response.status, data: {} };
    }
}


export async function getContractor(id) {
    let response;
    try {
        response = await fetch(`${serverUrl}/contractors/${id}`, getConfig());
        if (response.status == 200) {
            const text = await response.text();
            return { status: response.status, data: JSON.parse(text) };
        }

    } catch (error) {
        return { status: response.status, data: {} };
    }
}

export async function updateContractorData(id, email, name, address1, address2, bankAccountNumber, nip) {
    const response = await fetch(`${serverUrl}/contractors`, {
        method: 'PUT',
        headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${getToken()}`
        },
        body: JSON.stringify({ id, email, name, address1, bankAccountNumber: bankAccountNumber.replaceAll('-', ''), nip, address2 })
    });
    return { status: response.status, data: {} };
}

export async function deleteContractor(id) {
    const response = await fetch(`${serverUrl}/contractors/${id}`, {
        method: 'DELETE',
        headers: {
            "authorization": `Bearer ${getToken()}`
        }
    });
    return { status: response.status, data: {} };
}

export async function createContractor(email, name, address1, address2, bankAccountNumber, nip) {

    const response = await fetch(`${serverUrl}/contractors`, {
        ...postAuthorizedConfig(),
        body: JSON.stringify({ email, name, address1, address2, bankAccountNumber: bankAccountNumber.replaceAll('-', ''), nip })
    });

    if (response.status == 200) {
        const text = await response.text();
        return { status: response.status, data: JSON.parse(text) };
    }

    return { status: response.status, data: {} };

}

export async function markContractorConfirmed(id, confirmed = true) {
    const response = await fetch(`${serverUrl}/contractors/confirm`, {
        ...postAuthorizedConfig(),
        body: JSON.stringify({ id, confirmed })
    });

    return { status: response.status, data: {} };

}
