

import { serverUrl, getConfig } from "@/rest/token";

export const MESSAGE_BRIDGE_MIXIN = {

    methods: {

        async FetchMessage(id) {
            let response;
            try {
                response = await fetch(`${serverUrl}/message/${id}`, getConfig());
                if (response.status == 200) {
                    const text = await response.text();
                    return JSON.parse(text);
                }

            } catch (error) {
                return response.status;
            }
        }

    }
}