import { serverUrl, getToken, getConfig } from "@/rest/token";



export async function deleteDocument(id) {
    const response = await fetch(`${serverUrl}/documents/${id}`, {
        method: "DELETE",
        headers: {
            authorization: `Bearer ${getToken()}`
        }
    });
    return response.status;
}

export async function updateDocument(id, name, checkout, isPaid) {
    const str = isPaid ? 'true' : 'false';
    const body = JSON.stringify({ id, name, checkout, isPaid: str })

    const response = await fetch(`${serverUrl}/documents`, {
        method: 'PUT',
        headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${getToken()}`
        },
        body,
    });
    return response.status;
}

export async function getDocuments(year, month) {
    let response;
    try {
        response = await fetch(`${serverUrl}/documents?month=${month}&year=${year}`, getConfig());
        if (response.status == 200) {
            const text = await response.text();
            return JSON.parse(text);
        }

    } catch (error) {
        return response.status;
    }
}

export async function uploadDocument(data) {
    const response = await fetch(`${serverUrl}/documents`, {
        method: "POST",
        headers: {
            authorization: `Bearer ${getToken()}`
        },
        body: data
    });
    return response;
}