import { defineStore, storeToRefs } from "pinia";
import { ref, watch, computed } from "vue";
import { useSelectedDateStore } from "@/stores/date";
import { FetchPeriodics, deletePeriodic, DeleteFileFromPeriodic, updatePeriodic, UpdatePeriodicInstance, savePeriodicToServer } from "@/rest/periodic";

export const usePeriodicsStore = defineStore("periodics", () => {
    const { month, year } = storeToRefs(useSelectedDateStore());

    const periodics = ref([]);
    const loading = ref(false);

    const all = computed(() => {
        return periodics.value.map((p, index) => {
            return { type: 'periodic', storeId: index, ...p };
        })
    });

    async function fetchAll() {
        loading.value = true;
        periodics.value = [];
        const res = await FetchPeriodics(year.value, month.value);
        if (typeof res === "number" || !res) {
            return false;
        }
        periodics.value = await res;
        loading.value = false;
        return true;
    }
    watch(ref([month, year]), fetchAll, { deep: true });

    async function addOne(contractorId, dateStarts, repeats, name, checkout) {
        const status = await savePeriodicToServer(contractorId, dateStarts, repeats, name, checkout);

        if (status === 200) {
            fetchAll();
        }

        return status;
    }

    /**
     * 
     * @param {*} id server id of periodical to delete
     * @param {*} date "yyyy-mm-dd" string
     * @param {*} typeOfDeletion  "one of types accepted by the server"
     * @returns 
     */
    async function deleteOne(id, date, typeOfDeletion) {
        const status = await deletePeriodic(id, date, typeOfDeletion);
        // const status =200
        if (status == 200) {

            if (typeOfDeletion.toLowerCase().includes('single')) {
                //dewlete single removes matchind date and id
                const index = periodics.value.findIndex(v => {
                    return (v.date === date && v.id === id);
                })
                if (index !== -1)
                    periodics.value.splice(index, 1);
            }

            if (typeOfDeletion.toLowerCase() === 'all') {
                //delete all records with matching id
                periodics.value = periodics.value.filter(v => v.id !== id);
            }

            if (typeOfDeletion.toLowerCase().includes('following')) {
                //remove following delete all records with matching id and date later than given
                const itemDate = new Date(date);
                let indeciesToDelete = [];

                periodics.value.forEach((v, index) => {
                    const d = new Date(v.date);
                    if (v.id === id && d >= itemDate)
                        indeciesToDelete.push(index);
                });

                periodics.value = periodics.value.filter((v, i) => !indeciesToDelete.includes(i));
            }

        }
        return status;
    }

    async function updateOne(id, contractorId, name, checkout) {
        const status = await updatePeriodic(id, contractorId, name, checkout);
        if (status === 200) {
            periodics.value.forEach(function (p, i, array) {
                // update all entries with matching id
                if (array[i].id === id) {
                    periodics.value[i].contractor.id = contractorId;
                    periodics.value[i].name = name;
                    periodics.value[i].checkout = checkout;
                }
            });
        }
        return status;
    }

    async function updateOneInstance(data) {
        const resp = await UpdatePeriodicInstance(data);
        if (resp.status === 200) {
            const parentId = parseInt(data.get("parentId"), 10);
            const date = data.get("date");
            const isPaid = data.get("isPaid");
            const description = data.get("description");

            const index = periodics.value.findIndex(p => {
                return parentId === p.id && date === p.date;
            });

            const respData = JSON.parse(await resp.text());
            periodics.value[index] = respData;
            periodics.value[index].isPaid = (isPaid === "true");

            if (description) {
                periodics.value[index].description = description;
            }

        }
        return resp;
    }


    // dettach file
    async function detachFile(id, date) {
        const status = await DeleteFileFromPeriodic(id, date);
        if (status === 200) {
            const index = periodics.value.findIndex(p => {
                return (p.id === id & p.date === date)
            });
            periodics.value[index].file = null;
            periodics.value[index].canRecieveFile = true;
        }
        return status;
    }

    function dropAll() {
        periodics.value = []
    }

    return { all, dropAll, deleteOne, detachFile, updateOne, updateOneInstance, addOne, loading, reload: fetchAll }



})