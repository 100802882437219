import { serverUrl, getConfig, getToken } from "@/rest/token";

export async function fetchInvoices(year, month) {
    const response = await fetch(`${serverUrl}/invoices?month=${month}&year=${year}`, getConfig());
    if (response.status == 200 || response.status == 500) {
        try {
            const text = await response?.text();
            return { status: response.status, data: JSON.parse(text) };
        } catch (e) {
            // empty
        }
    }

    return { status: response.status, data: {} };

}

export async function updateInvoice(id, description, checkout, isPaid) {
    const body = JSON.stringify({ id, description, moneyToBeCharged: checkout, isPaid: isPaid.toString() })
    const response = await fetch(`${serverUrl}/invoices`, {
        method: 'PUT',
        headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${getToken()}`
        },
        body,
    });
    return response.status;
}

export async function deleteInvoice(id) {
    const response = await fetch(`${serverUrl}/invoices/${id}`, {
        method: 'DELETE',
        headers: {
            "authorization": `Bearer ${getToken()}`
        }
    });
    return response.status;
}