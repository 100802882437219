<template>
    <div class="progress-bar text-secondary font-weight-bold" ref="indicatorDiv">
        {{ props.percentage }}%
    </div>
</template>

<style scoped>
.progress-bar {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    transition: 0.5s;
    background:
        radial-gradient(closest-side, var(--bs-body-bg) 65%, transparent 80% 100%),
        conic-gradient(var(--bs-border-color) var(--cs-storage-percentage), var(--bs-body-bg) 0);
}
</style>

<script setup>
import { defineProps, ref, watch } from 'vue';

const props = defineProps(['percentage']);
const indicatorDiv = ref(null);

watch(props, () => {
    document.querySelector(':root').style.setProperty('--cs-storage-percentage', props.percentage + '%');
}, { immediate: true })

</script>