

import { serverUrl, getConfig } from "@/rest/token";

export const FILE_BRIDGE_MIXIN = {

    methods: {
        async getFileDownloadLink(fileid) {
            let response;
            try {
                response = await fetch(`${serverUrl}/file/${fileid}`, getConfig());
                if (response.status == 200) {
                    const text = await response.text();
                    const uuid = JSON.parse(text).id;
                    return `${serverUrl}/file/download/${uuid}`;
                }

            } catch (error) {
                return response.status;
            }
        }
    }
}