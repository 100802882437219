import { createRouter, createWebHashHistory } from 'vue-router'
import invoices from "../views/invoices.vue"
import LoginView from "@/views/login.vue"
import { tokenExists } from '@/rest/token'

const routes = [
  {
    path: '/',
    name: 'home',
    component: invoices
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import("@/views/settings.vue"),
  },
  {
    path: '/forgot-password',
    name: 'resetpassword',
    component: () => import("@/views/resetPassword.vue"),
  },
  {
    path: '/contractors',
    name: 'contractors',
    component: () => import("@/views/contractors.vue"),
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
  },
  {
    path: '/setup/1',
    name: 'setup1',
    component: () => import("@/views/setup/stage1.vue"),
  },
  {
    path: '/setup/2',
    name: 'setup2',
    component: () => import("@/views/setup/stage2.vue"),
  },
  {
    path: '/setup/3',
    name: 'setup3',
    component: () => import("@/views/setup/stage3.vue"),
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import("@/views/admin.vue"),
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})
const allowedRoutes = ['resetpassword'];
router.beforeEach(async (to) => {
  // if (to.name.includes("setup") || allowedRoutes.includes(to.name)) {
  if (allowedRoutes.includes(to.name)) {
    return true;
  }
  if (!tokenExists() && to.name !== 'login') {
    return { name: 'login' };
  }
})

export default router
