import { serverUrl, getConfig } from "@/rest/token";

export const ARCHIVIZER_BRIDGE_MIXIN = {

    methods: {
        async getArchiveDownloadLink(year, monthUTC) {
            const response = await fetch(`${serverUrl}/archivizer/${year}/${monthUTC}`, getConfig());
            if (response.status == 200) {
                const text = await response.text();
                const uuid = JSON.parse(text).id;
                return `${serverUrl}/archivizer/download/${uuid}`;
            }
            return response.status;
        }
    }
}