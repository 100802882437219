import { defineStore } from "pinia";
import { serverUrl, getConfig } from "@/rest/token";
import { watch, ref } from "vue";
import { computed } from "vue";
import { useListingStore } from "./listing-store";
import { tokenExists } from "@/rest/token";

export const useStorageSizeStore = defineStore("storage", () => {

    const listing = useListingStore();

    const bytes = ref(0);

    async function fetchSize() {
        if (!tokenExists())
            return;
        const res = await fetch(`${serverUrl}/file-cleaner/size`, getConfig());
        if (res.status !== 200) {
            return;
        }
        const data = JSON.parse(await res.text());

        if (data.size) {
            bytes.value = data.size;
        }

    }

    watch(() => listing.entries, () => {
        if (listing.loading === false) {
            fetchSize();
        }
    }, { immediate: true });

    const mb = computed(() => bytes.value / 1_000_000)

    return { update: fetchSize, mb, bytes };

})