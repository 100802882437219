<template>
    <div class="col-12">
        <label for="companyName" class="form-label">Nazwa dostawcy</label>
        <div class="input-group">
            <input type="text" class="form-control" aria-label="Text input with segmented dropdown button"
                :value="this.currentName" disabled>
            <button class="btn btn-outline-secondary d-flex align-items-center" type="button" id="button-addon2"
                @click="copy(this.currentName)"><span class="material-symbols-outlined">content_copy</span></button>

            <button class="btn btn-sm btn-outline-secondary d-flex align-items-center" type="button" id="button-addon2"
                data-bs-toggle="modal" data-bs-target="#modalAddContractor"
                @click="$emit('message-adding-contractor', null)">
                <span class="material-symbols-outlined">domain_add</span>
            </button>

            <button type="button" class="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                data-bs-toggle="dropdown" aria-expanded="false">
                <span class="visually-hidden">Toggle Dropdown</span>
            </button>

            <ul class="dropdown-menu dropdown-menu-end">
                <input class="form-control form-control-sm" type="text" placeholder="Search" v-model="this.searchStr"
                    aria-label=".form-control-sm example">
                <li>
                    <hr class="dropdown-divider">
                </li>
                <li v-for="(contr, index) in displayedContractors" :key="index">
                    <a class="dropdown-item" @click="picked(contr.id)">{{ contr.name }}</a>
                </li>
            </ul>
        </div>
    </div>
</template>


<script>
import { useContractorsStore } from '@/stores/contractors';
import { storeToRefs } from 'pinia';
export default {
    name: 'ContractorPicker',
    props: {
        id: Number
    },
    setup() {
        const store = useContractorsStore();
        const { confirmedOnly } = storeToRefs(store);
        return { store, contractors: confirmedOnly };
    },
    data() {
        return {
            searchStr: '',
            currentId: this.id
        }
    },
    watch: {
        async id(val) {
            this.currentId = val;
        }
    },
    computed: {
        displayedContractors() {
            if (!this.searchStr)
                return this.contractors;

            return this.contractors.filter(c => c.name.toLowerCase().includes(this.searchStr.toLowerCase()));
        },
        currentContractor() {
            return this.contractors.find(c => c.id == this.currentId);
        },
        currentName() {
            return this.contractors.find(c => c.id == this.currentId)?.name;
        }
    },
    methods: {
        picked(id) {
            this.currentId = id;
            this.$emit("contractor-changed", this.currentContractor);
        }

    }

}
</script>