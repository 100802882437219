<style scoped>
.mybutton {
    border: none;
}
</style>
<template>
    <div class="bg-body-tertiary p-3 rounded mt-2">
        <div class="d-flex justify-content-between">
            <div class="d-flex expand-listing-item-trigger" data-bs-toggle="collapse" :data-bs-target="`#${this.uuid}`"
                aria-expanded="false" :aria-controls="this.uuid" @click="this.isFormShown = !this.isFormShown">
                <div class="pe-3">

                    <div>
                        <span class="material-symbols-outlined c1">mail</span>
                    </div>

                    <div>
                        <span class="material-symbols-outlined text-secondary">expand_more</span>
                    </div>
                </div>

                <div class="mr-3">
                    <div class="mb-1" :class="showAlert ? 'text-danger' : ''">{{ title }}</div>
                    <small class="text-secondary">{{ associatedData.date }}</small>
                </div>
                <div v-if="showAlert">
                    <div></div>
                    <span class="material-symbols-outlined text-danger">error</span>
                </div>

            </div>
            <!-- todo link payment toggle here -->
            <div class="d-flex align-items-center">
                <div class="mb-0 d-flex flex-row d-flex align-items-center">
                    <div class="pe-2">{{ checkout ? checkout : 'Nieznany' }}</div>
                    <button type="button" class="btn btn-sm d-flex align-items-center rounded-pill mybutton"
                        @click="togglePayment">

                        <span v-if="isPaid" class="material-symbols-outlined text-success">paid</span>
                        <span v-if="!isPaid" class="material-symbols-outlined text-primary">send_money</span>
                    </button>
                    <button type="button" class="btn btn-sm d-flex align-items-center rounded-pill ms-2"
                        data-bs-toggle="dropdown" aria-expanded="false">
                        <span class="material-symbols-outlined">more_vert</span>
                    </button>
                    <ul class="dropdown-menu dropdown-menu-end">
                        <li @click="deleteMe"><a class="dropdown-item">Usuń</a></li>
                        <li @click="$emit('show-message-modal', associatedData.message)" data-bs-toggle="modal"
                            data-bs-target="#messagePreviewModal"><a class="dropdown-item">Podgląd wiadomości</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="collapse" :id="this.uuid">

            <div class="col-sm-12 mt-3 mb-3">
                <PayButton :isPaid="isPaid" :canPay="true" @click="togglePayment"> </PayButton>
            </div>

            <div class="col-12 mb-3">
                <label for="email" class="form-label">Wysłane z adresu e-mail</label>
                <div class="input-group">
                    <input class="form-control" type="text" placeholder="you@example.com" :value="this.sentFromEmail"
                        aria-label="Disabled input example" disabled>
                    <button class="btn btn-outline-secondary rounded-end d-flex align-items-center" type="button"
                        @click="copy(this.sentFromEmail)" id="button-addon2"><span
                            class="material-symbols-outlined">content_copy</span></button>
                    <div class="invalid-feedback">
                        Please enter a valid email address for shipping updates.
                    </div>
                </div>
            </div>

            <p class="fs-5 text-secondary">Dane pobrane z faktury</p>

            <div class="col-12 mb-3">
                <AttachmentButton :fileInfo="this.associatedData.file" :canRecieveFile="this.associatedData.canRecieveFile">
                </AttachmentButton>
            </div>

            <form class="row g-3">
                <div class="col-12">
                    <label for="nazwaDostawcy" class="form-label">Nazwa dostawcy</label>
                    <div class="input-group">
                        <input type="text" class="form-control" aria-label="Text input with segmented dropdown button"
                            v-model="this.contractor.name" :disabled="isContractorConfirmed" id="nazwaDostawcy"
                            :class="{ 'form-control-danger': !this.contractor.name || this.contractor.name?.trim().length === 0 }">
                        <button class="btn btn-outline-secondary rounded-end d-flex align-items-center" type="button"
                            @click="copy(this.contractor.name)" id="button-addon2"><span
                                class="material-symbols-outlined">content_copy</span></button>
                    </div>
                </div>

                <div class="col-md-6">
                    <label for="inputAddress" class="form-label">Address</label>
                    <div class="input-group">
                        <input type="text" class="form-control" id="inputAddress" :disabled="isContractorConfirmed"
                            v-model="this.contractor.address1"
                            :class="{ 'form-control-danger': !this.contractor.address1 || this.contractor.address1?.trim().length === 0 }">
                        <button class="btn btn-outline-secondary rounded-end d-flex align-items-center" type="button"
                            @click="copy(this.contractor.address1)" id="button-addon2"><span
                                class="material-symbols-outlined">content_copy</span></button>
                    </div>
                </div>
                <div class="col-md-6">
                    <label for="inputAddress" class="form-label">Address</label>
                    <div class="input-group">
                        <input type="text" class="form-control" id="inputCity" placeholder=""
                            :disabled="isContractorConfirmed" v-model="this.contractor.address2">
                        <button class="btn btn-outline-secondary rounded-end d-flex align-items-center" type="button"
                            @click="copy(this.contractor.address2)" id="button-addon2"><span
                                class="material-symbols-outlined">content_copy</span></button>
                    </div>
                </div>
                <div class="col-md-6">
                    <label for="Numerrachunku" class="form-label">Numer rachunku dostawcy</label>
                    <div class="input-group">
                        <input type="text" class="form-control" id="Numerrachunku" :disabled="isContractorConfirmed"
                            v-model="this.contractor.bankAccountNumber" v-mask="'##-####-####-####-####-####-####'"
                            :class="{ 'form-control-danger': !this.contractor.bankAccountNumber || this.contractor.bankAccountNumber?.trim().length === 0 }">
                        <button class="btn btn-outline-secondary rounded-end d-flex align-items-center" type="button"
                            @click="copy(this.contractor.bankAccountNumber.replaceAll('-', ''))" id="button-addon2"><span
                                class="material-symbols-outlined">content_copy</span></button>
                    </div>
                </div>
                <div class="col-md-6">
                    <label for="nip" class="form-label">NIP</label>
                    <div class="input-group">
                        <input type="text" class="form-control" id="nip" placeholder="" :disabled="isContractorConfirmed"
                            v-mask="'##########'" v-model="this.contractor.nip">
                        <button class="btn btn-outline-secondary rounded-end d-flex align-items-center" type="button"
                            @click="copy(this.contractor.nip.replaceAll('-', ''))" id="button-addon2"><span
                                class="material-symbols-outlined">content_copy</span></button>
                    </div>
                </div>
                <div class="col-12">
                    <label for="companyName" class="form-label">Opis płatności</label>
                    <div class="input-group">
                        <input type="text" class="form-control" id="opisplatnosci" placeholder=""
                            v-model="this.description">
                        <button class="btn btn-outline-secondary rounded-end d-flex align-items-center" type="button"
                            @click="copy(this.description)" id="button-addon2">
                            <span class="material-symbols-outlined">content_copy</span>
                        </button>
                    </div>
                </div>
                <div class="col-md-6">
                    <label for="kwota" class="form-label">Kwota na fakturze</label>
                    <div class="input-group">
                        <input type="number" class="form-control" id="kwota" v-model="checkout" @focusout="checkoutOutfocus"
                            :class="{ 'form-control-danger': !checkout }">
                        <button class="btn btn-outline-secondary rounded-end d-flex align-items-center" type="button"
                            @click="copy(this.checkout)" id="button-addon2"><span
                                class="material-symbols-outlined">content_copy</span></button>
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex flex-row">
                        <a>
                            <button type="button" class="d-flex align-items-center btn btn-outline-primary btn-sm"
                                v-if="!isContractorConfirmed" @click="saveContractorBtn">
                                <span class="material-symbols-outlined c2">domain_add</span>
                                <span class="ms-1" style="color:var(--bs-body-color);">
                                    Dodaj dostawcę
                                </span>
                            </button>
                            <button type="button" class="d-flex align-items-center btn btn-outline-primary btn-sm" v-else>
                                <router-link :to="{ name: 'contractors', query: { id: associatedData.contractorId } }"
                                    class="text-decoration-none">
                                    <span class="ms-1" style="color:var(--bs-body-color) !important;">
                                        Edytuj dostawcę
                                    </span>
                                </router-link>
                            </button>

                        </a>
                    </div>
                    <div class="d-flex flex-row">
                        <a>
                            <button type="button" class="btn btn-primary btn-sm" @click="saveBtn"
                                :disabled="isButtonDisabled">
                                Zapisz zmiany
                            </button>
                        </a>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { FILE_BRIDGE_MIXIN } from '@/mixins/bridges/files';
import PayButton from './payButton.vue';
import AttachmentButton from './attachmentButton.vue';
import { useInvoicesStore } from '@/stores/invoices';
import { useContractorsStore } from '@/stores/contractors';
import { watch, ref } from 'vue';

export default {
    name: "MessageListingItem",
    mixins: [FILE_BRIDGE_MIXIN],
    components: { PayButton, AttachmentButton },
    props: {
        uuid: Number,
        associatedData: Object,
        storeId: Number
    },
    setup(props) {
        const invoices = useInvoicesStore();
        const contractors = useContractorsStore();

        const isContractorConfirmed = ref(false);
        const isContractorComplete = ref(false);
        const title = ref("");
        watch(() => contractors.getById(props.associatedData.contractorId), (cont) => {
            if (cont) {
                isContractorConfirmed.value = cont.isConfirmed;
                isContractorComplete.value = contractors.hasAllRequieredData(cont.id);
                title.value = cont.name;
            }

        }, { immediate: true, deep: true });

        return { invoices, contractors, isContractorConfirmed, isContractorComplete, title };
    },
    data() {
        return {
            isFormShown: false,
            contractorId: this.associatedData.contractorId,
            checkout: this.associatedData.checkout ? parseFloat(this.associatedData.checkout).toFixed(2) : null,
            checkoutStart: this.associatedData.checkout ? this.associatedData.checkout.toFixed(2) : null,
            description: this.associatedData.description,
            isPaid: this.associatedData.isPaid,

            contractor: {},
        }
    },
    computed: {
        isButtonDisabled() {
            if (this.isComplete && this.isContractorConfirmed) {
                let value =
                    this.checkout !== this.checkoutStart ||
                    this.description !== this.associatedData.description;
                return !value;
            }
            return !this.isComplete || !this.isContractorFormFilled;
        },
        sentFromEmail() {
            const from = this.associatedData.message.from;
            //eslint-disable-next-line
            const emailRegex = /[\w\.-]+@[\w\.-]+\.\w+/g;
            const matches = from.match(emailRegex);
            return (matches ? Array.from(new Set(matches)) : []).at(0);
        },
        isComplete() {
            if (this.checkout !== null) {
                return this.checkout.toString().trim().length !== 0;
            }
            return false;
        },
        isContractorFormFilled() {
            const v = this.contractor?.name?.length !== 0 &&
                this.contractor?.address1?.length !== 0 &&
                this.contractor?.bankAccountNumber?.length === 32;
            return v;
        },
        contractorAlert() {
            if (!this.isContractorConfirmed) {
                return !this.isContractorComplete
            }
            return false; // if confirmed show no alert
        },
        showAlert() {
            return !this.isComplete || this.contractorAlert;
        },
        isSaveContractorButtonDisabled() {
            return this.isContractorConfirmed || !this.isContractorFormFilled;
        }
    },
    methods: {
        async saveBtn() {
            if (await this.invoices.updateOne(this.associatedData.id, this.description, parseFloat(this.checkout), this.isPaid) !== 200) {
                this.pushError("Nie udało się zapisać danych faktury");
            }
            if (this.isContractorConfirmed)
                return;
            const saveStatus = await this.contractors.updateOne(this.contractorId, this.contractor.email, this.contractor.name, this.contractor.address1 || null, this.contractor.address2 || null, this.contractor.bankAccountNumber, this.contractor.nip || null);
            if (saveStatus !== 200) {
                this.pushError("Nie udało się zapisac danych dostawcy!");
            }
        },

        checkoutOutfocus() {
            const checkout = parseFloat(this.checkout).toFixed(2);
            if (isNaN(checkout)) {
                return;
            }
            this.checkout = checkout;
        },
        async fetchContractor(id) {
            const contractor = await this.contractors.getById(id);
            for (const key in contractor) {
                let value = contractor[key];
                if (value == null) {
                    value = "";
                }
                this.contractor[key] = value;
            }
            this.contractorId = contractor.id;
            this.title = contractor.name;
        },
        async deleteMe() {
            const status = await this.invoices.deleteOne(this.associatedData.id);
            if (status != 200) {
                this.pushError("Nie udało się usunąć faktury")
                return;
            }
        },
        async togglePayment() {
            this.isPaid = !this.isPaid;
            if (await this.invoices.updateOne(this.associatedData.id, this.description, parseFloat(this.checkout), this.isPaid) !== 200) {
                this.pushError("Nie udało się zapisać danych faktury");
                return;
            }

        },
        async saveContractorBtn() {
            if (await this.invoices.updateOne(this.associatedData.id, this.description, parseFloat(this.checkout), this.isPaid) !== 200) {
                this.pushError("Nie udało się zapisać danych faktury");
                return;
            }
            const saveStatus = await this.contractors.updateOne(this.contractorId, this.contractor.email, this.contractor.name, this.contractor.address1 || null, this.contractor.address2 || null, this.contractor.bankAccountNumber, this.contractor.nip || null);
            if (saveStatus !== 200) {
                this.pushError("Nie udało się zapisac danych dostawcy!");
            }

            if (await this.contractors.markConfirmed(this.contractorId, true) !== 200) {
                this.pushError("Nie udało się zapisać dostawcy");
                return;
            }
        }

    },
    watch: {
        async isFormShown(value) {
            if (value == true) {
                this.fetchContractor(this.associatedData.contractorId)
            }
        },

    }


}
</script>