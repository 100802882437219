import { defineStore } from "pinia";

export const useSelectedDateStore = defineStore("selected-date", {

    state: () => {
        return {
            month: 0,
            year: 1900
        }
    },
    actions: {
        reset() {
            this.month = this.currentMonth;
            this.year = this.currentYear;
        }
    },
    getters: {
        firstDayOfMonthAsString() {  // example 2023-11-23
            if (this.month == this.currentMonth && this.year == this.currentYear) {
                return new Date(Date.now()).toISOString().substring(0, 10);
            }
            const jsDate = new Date((
                Date.UTC(
                    this.year,
                    this.month,
                    1, //first day 
                    0, 0, 0, 0)
            ));

            return jsDate.toISOString().substring(0, 10);
        },
        currentMonth() { return new Date().getUTCMonth() },
        currentYear() { return new Date().getUTCFullYear() },
    }



})