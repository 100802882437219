import { getContractors, updateContractorData, deleteContractor, createContractor, markContractorConfirmed, getContractor } from "@/rest/contractor";
import { defineStore } from "pinia";
import { computed } from "vue";
import { ref } from 'vue';

export const useContractorsStore = defineStore("contractors", () => {

    const contractors = ref(new Map());

    const all = computed(() => {
        if (contractors.value.size === 0) {
            fetchAll();
        }
        return Array.from(contractors.value, ([id, data]) => ({ storeId: id, ...data }));
    });

    const confirmedOnly = computed(() => {
        return all.value.filter(c => c.isConfirmed == true);
    });

    const map = computed(() => {
        if (contractors.value.size === 0) {
            fetchAll();
        }
        return contractors.value;
    });

    let currentlyFetching = new Set(); // do not fetch the same contractor twice wait make request once and wait for it to arrive
    function getById(id) {
        const item = map.value.get(id);
        if (item != undefined) {
            return item;
        }
        if (!currentlyFetching.has(id)) {
            fetchOne(id).then(() => currentlyFetching.delete(id));
        }
        currentlyFetching.add(id);
        return null;
    }

    async function fetchOne(id) {
        const resp = await getContractor(id);
        if (resp.status === 200) {
            contractors.value.set(id, resp.data);
            return resp.data;
        }
    }

    async function fetchAll(confirmedOnly = true) {
        const data = await getContractors(confirmedOnly);
        if (data.status === 200) {
            data.data.forEach(c => {
                contractors.value.set(c.id, c);
            });
        }
        return data.status;
    }

    async function updateOne(id, email, name, address1, address2, bankAccountNumber, nip) {
        const data = await updateContractorData(id, email, name, address1, address2, bankAccountNumber, nip);
        if (data.status === 200) {
            contractors.value.get(id).email = email;
            contractors.value.get(id).name = name;
            contractors.value.get(id).address1 = address1;
            contractors.value.get(id).address2 = address2;
            contractors.value.get(id).bankAccountNumber = bankAccountNumber;
            contractors.value.get(id).nip = nip;
        }
        return data.status;
    }

    async function deleteOne(id) {
        const data = await deleteContractor(id);
        if (data.status === 200) {
            contractors.value.delete(id);
        }
        return data.status;
    }

    async function addOne(email, name, address1, address2, bankAccountNumber, nip) {
        const data = await createContractor(email, name, address1, address2, bankAccountNumber, nip);
        if (data.status === 200) {
            fetchAll(); //! that is temporary just to make it work
        }
        return data;
    }

    async function markConfirmed(id, confirmed = true) {
        const data = await markContractorConfirmed(id, confirmed);
        if (data.status === 200) {
            contractors.value.get(id).isConfirmed = confirmed;
        }
        return data.status;
    }

    function hasAllRequieredData(id) {
        const c = contractors.value.get(id);
        let counter = 0;

        if (c.address1) counter++;
        if (c.bankAccountNumber) counter++;

        return counter == 2;
    }

    return { getById, fetchAll, updateOne, deleteOne, addOne, markConfirmed, hasAllRequieredData, confirmedOnly, contractors };

});