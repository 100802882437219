<template>
  <TopBar />
  <UiMessageStack></UiMessageStack>
  <router-view />
</template>

<style>
@import url("https://cdn.jsdelivr.net/npm/@docsearch/css@3");

.material-symbols-outlined {
  font-family: 'Material Symbols Outlined', sans-serif;
  font-size: 24px;
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
  font-weight: normal;
  font-style: normal;
  display: inline-block;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.list-container {
  max-width: 650px;
  margin: auto;
}

body {
  font-family: "IBM Plex Sans", sans-serif !important;
  overflow-y: scroll;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.footer {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.form-control-danger {
  border-color: #E74C3C;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 0, 0, 0.6);
}

body {}
</style>

<script>
import '@fontsource/material-symbols-outlined';
import "@fontsource/ibm-plex-sans";
import TopBar from "@/components/TopBar.vue";
import { loadScript } from "vue-plugin-load-script";
import UiMessageStack from "./components/messageStack/UiMessageStack.vue";
export default {
  name: "App",
  components: { TopBar, UiMessageStack },
  created() {
    document.title = "CostLinker";
    if (!localStorage.getItem('theme'))
      localStorage.setItem('theme', 'dark');
    loadScript("/color-modes.js");
  },
};
</script>
