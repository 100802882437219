import { useSelectedDateStore } from '@/stores/date'
import { defineStore, storeToRefs } from "pinia";
import { ref, watch, computed } from "vue";
import { fetchInvoices, updateInvoice, deleteInvoice } from '@/rest/invoices';


export const useInvoicesStore = defineStore("invoices", () => {
    const { month, year } = storeToRefs(useSelectedDateStore());

    const invoices = ref([]);
    const loading = ref(false);
    const error = ref(0);

    const all = computed(() => {
        return invoices.value.map((i, index) => {
            return { type: 'invoice', storeId: index, name: i.title, ...i };
        })
    });

    async function fetchAll() {
        error.value = 0;
        loading.value = true;
        invoices.value = [];
        const res = await fetchInvoices(year.value, month.value);
        if (res.status !== 200) {
            loading.value = false;
            error.value = res.status;
            return false;
        }
        invoices.value = res.data;
        loading.value = false;
        return true;
    }
    watch(ref([month, year]), fetchAll, { deep: true });

    async function deleteOne(id) {
        const status = await deleteInvoice(id);
        if (status === 200) {
            const index = invoices.value.findIndex(i => i.id === id);
            invoices.value.splice(index, 1);
        }
        return status;
    }

    async function updateOne(id, description, checkout, isPaid) {
        const status = await updateInvoice(id, description, checkout, isPaid);

        if (status === 200) {
            const index = invoices.value.findIndex(i => i.id === id);
            invoices.value[index].description = description;
            invoices.value[index].checkout = checkout;
            invoices.value[index].isPaid = isPaid;
        }
        return status;
    }

    function dropAll() {
        invoices.value = [];
    }

    return { all, deleteOne, updateOne, dropAll, loading, reload: fetchAll, raw: invoices, error };

});