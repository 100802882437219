<style scoped>
#forgotPasswordText:hover {

    text-decoration: underline;
    opacity: 100%;
    cursor: pointer;
}
</style>
<template>
    <div class="container">
        <div class="list-container">
            <h4 class="container text-secondary">Logowanie</h4>
        </div>
    </div>

    <main class="container">
        <div class="list-container">
            <form class="bg-body-tertiary p-3 rounded mt-4" autocomplete="on">
                <div class="col-md-12 mb-3">
                    <label for="username" class="form-label">Login</label>
                    <div class="input-group">
                        <input type="text" class="form-control" id="username" v-model="this.username" name="username"
                            autocomplete="username" />
                    </div>
                </div>

                <div class="col-md-12 mb-4">
                    <label for="password" class="col-form-label">Hasło</label>
                    <div class="input-group">
                        <input type="password" class="form-control" id="password" v-model="this.password" name="password"
                            autocomplete="current-password" @keydown="passwordKeyDown" />
                    </div>
                </div>
                <div class="col-md-12 mb-2 d-flex justify-content-between text-center text-danger">
                    <button type="button" class="btn btn-primary bg-dark btn-sm" @click="startPasswordReset"
                        :disabled="canResetPasswordDisabled">
                        Przypomnij hasło
                    </button>
                    <div class="text-danger">{{ this.message }}</div>
                    <button type="button" class="btn btn-primary btn-sm" @click="forward" :disabled="isButtonDisabled">
                        Zaloguj się
                    </button>
                </div>
            </form>
        </div>
    </main>
</template>

<script>

import { AUTH_BRIDGE_MIXIN } from "@/mixins/bridges/auth";
import { setToken } from "@/rest/token";
import { VALIDATE_EMAIL_MIXIN } from "@/mixins/emailValidation";
import { PASSWORD_RESET_MIXIN } from "@/mixins/bridges/passwordReset";
import { useUserDataStore } from "@/stores/user"
import { useSelectedDateStore } from '@/stores/date'
export default {

    name: 'LoginView',
    mixins: [AUTH_BRIDGE_MIXIN, VALIDATE_EMAIL_MIXIN, PASSWORD_RESET_MIXIN],
    components: {},
    setup() {
        const userStore = useUserDataStore();
        const dateStore = useSelectedDateStore();
        return { userStore, dateStore };
    },
    data() {
        return {
            username: '',
            password: '',
            message: ''
        }
    },
    methods: {
        async forward() {
            const res = await this.loginUser(this.username.trim(), this.password.trim());
            if (res == false) {
                this.message = "Niepoprawne dane logowania";
                return;
            }
            setToken(res.access_token);

            this.dateStore.reset();
            this.userStore.send_to_mail = res.send_to_address;
            this.userStore.user_name = res.user_name;
            this.userStore.storage_limit_mb = res.storage_limit_mb;

            if ("logo_base64" in res) {
                this.userStore.logo_base64 = res.logo_base64;
            }
            this.userStore.isAdmin = res.isAdmin;
            this.$router.push({ name: 'home' });
        },
        async startPasswordReset() {
            const resp = await this.requestPasswordReset(this.username.trim());

            if (resp.status !== 200) {
                this.message = "Użytkownik nie istnieje";
                this.pushError("Nie udało się rozpocząc procesu resetu hasła, uzytkownik nie istnieje");
                return;
            }
            sessionStorage.setItem('login_user_name', this.username.trim());
            this.$router.push({ name: "resetpassword" });
        },
        passwordKeyDown({ key }) {
            if (!this.isButtonDisabled && key == 'Enter') {
                this.forward();
            }
        }
    },
    computed: {
        isButtonDisabled() {
            return this.username.length === 0 || this.password.length === 0;
        },
        canResetPasswordDisabled() {
            return this.username.length === 0;// || this.password.length !== 0 || !this.validateEmail(this.username.trim());
        }
    },
    watch: {
        username() {
            this.message = '';
        },
        password() {
            this.message = '';
        }
    }

};
</script>
