<template>
    <div class="d-flex align-items-center">
        <label for="companyName" class="form-label pe-2">Załącznik</label>

        <div v-if="canRecieveFileVar || hasFile" style="display: contents;">
            <button type="button" class="btn btn-outline-success mw-100 truncate d-flex justify-content-center"
                @click="fileButtonClicked">
                {{ fileBtnText }}
            </button>

            <span @click="$emit('delete-file')" v-if="canDeleteFiles && hasFile" role="button"
                class="material-symbols-outlined text-danger ms-2">Delete</span>

            <a style="display: none;" ref="downloadLink" v-if="hasFile" target="_blank">test</a>

            <form ref="fileMiniForm" style="display: none;">
                <input type="file" name="file" ref="uploadFileInput" @change="filePicked">
            </form>
        </div>
        <button v-else type="button" class="btn btn-outline-secondary mw-100 truncate" disabled
            style="opacity: 100%;">Zarchiwizowany</button>
    </div>
</template>

<script>
import { FILE_BRIDGE_MIXIN } from '@/mixins/bridges/files';
export default {
    name: 'AttachmentButton',
    mixins: [FILE_BRIDGE_MIXIN],
    emits: ['fileStatusChange', 'delete-file'],
    props: {
        fileInfo: Object,
        canRecieveFile: Boolean,
        canDeleteFiles: {
            type: Boolean,
            default: false
        },
        handlesUpload: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            pickedFilename: '',
            fileData: this.fileInfo,
            canRecieveFileVar: this.canRecieveFile
        }
    },
    computed: {
        hasFile() {
            if (this.fileData) {
                return true;
            }
            return false;
        },
        fileBtnText() {
            if (this.pickedFilename) {
                return this.pickedFilename;
            }
            if (this.pickedFilename === null) {
                return "Plik został usunięty";
            }
            return this.hasFile ? this.fileData.name : "Płatność nie ma pliku";
        },
    },
    watch: {
        pickedFilename() {
            if (this.pickedFilename) {
                this.$emit("fileStatusChange", true);
            } else {
                this.$emit("fileStatusChange", false);
            }
        },
        fileInfo() {
            this.fileData = this.fileInfo;
        },
        canRecieveFile() {
            this.canRecieveFileVar = this.canRecieveFile;
        }
    },

    methods: {
        async downloadAttachment() {
            const fileLink = await this.getFileDownloadLink(this.fileData.id);
            if (typeof fileLink === "number") {
                this.pushError("Nie udało się pobrać załącznika", "Błąd pobierania")
                return;
            }
            const a = this.$refs.downloadLink;
            a.setAttribute('href', fileLink);
            a.click();
        },

        filePicked() {
            this.pickedFilename = this.$refs.uploadFileInput.value.split('/').pop().split('\\').pop();
        },

        async fileButtonClicked() {
            if (this.hasFile) {
                await this.downloadAttachment();
            } else {
                this.$refs.uploadFileInput.click();
                this.$emit("fileStatusChange", false);
                this.pickedFilename = '';
            }
        },
        clear() {
            this.$refs.uploadFileInput.value = "";
            this.pickedFilename = null;
            this.fileData = null;
            this.canRecieveFileVar = true;
            this.$emit("fileStatusChange", false);
        },
        getFileFormRef() {
            return this.$refs.fileMiniForm;
        },
    }

}
</script>