

import { serverUrl, getConfig, getToken } from "@/rest/token";

export const SENDER_BRIDGE_MIXIN = {

    methods: {

        async requestSendEmails(year, month) {
            const response = await fetch(`${serverUrl}/mailer/${year}/${month}`, getConfig());
            return response.status;
        },

        async sendArchivizeRequest(year, month) {
            const response = await fetch(`${serverUrl}/file-cleaner/${year}/${month}`, {
                method: "DELETE",
                headers: {
                    authorization: `Bearer ${getToken()}`
                }
            });
            return response;
        }

    }
}