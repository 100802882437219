export const GLOBAL_MIXIN = {
    methods: {
        copy(text) {
            if (!text) {
                return;
            }
            navigator.clipboard.writeText(text);
            this.pushInfo(text, "Skopiowano", 1800);
        },
    },



}