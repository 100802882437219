<template>
  <div class="modal fade" :id="this.identifier" tabindex="-1" :aria-labelledby="this.identifier" aria-hidden="true"
    ref="modalDiv">
    <div class="modal-dialog modal-fullscreen-sm-down">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" :id="this.identifier">Dodaj dostawcę</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">

          <p class="fs-5 text-secondary">Dane dostawcy</p>

          <form class="row g-3">
            <div class="col-12">
              <label for="companyName" class="form-label">Nazwa dostawcy</label>
              <div class="input-group">
                <input type="text" class="form-control" aria-label="Text input with segmented dropdown button"
                  v-model="this.name" placeholder="Zus" />

              </div>
            </div>
            <div class="col-md-12">
              <label for="inputAddress" class="form-label">Address</label>
              <div class="input-group">
                <input type="text" class="form-control" id="inputAddress" placeholder="" v-model="this.address1" />

              </div>
            </div>
            <div class="col-md-12">
              <label for="inputAddress" class="form-label">Address (druga linia)</label>
              <div class="input-group">
                <input type="text" class="form-control" id="inputCity" placeholder="" v-model="this.address2" />

              </div>
            </div>
            <div class="col-md-12">
              <label for="kwota" class="form-label">Numer rachunku dostawcy</label>
              <div class="input-group">
                <input type="text" class="form-control" id="Numerrachunku" v-model="this.bankAccountNumber"
                  v-mask="'##-####-####-####-####-####-####'" placeholder="00 1111 2222 3333 4444 5555 6666 " />
              </div>
            </div>
            <div class="col-md-12">
              <label for="nip" class="form-label">NIP</label>
              <div class="input-group">
                <input type="text" class="form-control" id="nip" placeholder="" v-model="this.nip"
                  v-mask="'##########'" />
              </div>
            </div>

          </form>

          <hr class="divider">

          <div class="col-12 mb-3">
            <label for="email" class="form-label">Powiązany adres e-mail</label>
            <div class="input-group mb-2" v-for="(email, index) in this.emails" :key="index">
              <!-- <div class="input-group mb-2"> -->
              <input class="form-control" type="email" v-model="this.emails[index]" aria-label="Disabled input example"
                placeholder="dostawca@mail.pl">

              <button class="btn btn-outline-secondary d-flex align-items-center" type="button"
                :class="{ 'rounded-end': index !== this.emails.length - 1 }" v-if="index != 0"
                @click="this.emails.splice(index, 1)">
                <span class="material-symbols-outlined">remove</span>
              </button>

              <button class="btn btn-outline-secondary d-flex align-items-center rounded-end" type="button"
                v-if="index === this.emails.length - 1" :disabled="disableEmailPlusButton" @click="this.emails.push('')">
                <span class="material-symbols-outlined">add</span>
              </button>

              <div class="invalid-feedback">
                Please enter a valid email address for shipping updates.
              </div>
            </div>
          </div>


        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" :id="`${identifier}-close-btn`">
            Zamknij
          </button>
          <button type="button" class="btn btn-primary" @click="save" :disabled="isSaveButtonDisabled">Dodaj</button>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { useContractorsStore } from "@/stores/contractors";

export default {
  name: "AddContractorModal",
  mixins: [],
  props: {
    identifier: String,
  },
  setup() {
    const store = useContractorsStore();
    return { store };
  },
  data() {
    return {
      name: "",
      address1: "",
      address2: "",
      bankAccountNumber: "",
      nip: "",
      emails: [""],
      processing: false,
    }
  },
  mounted() {
    const that = this;
    this.$refs.modalDiv.addEventListener('hidden.bs.modal', function () {
      that.$emit('contractorModalClosed');
    });
  },
  computed: {
    isSaveButtonDisabled() {
      if (this.processing === true) {
        return true;
      }

      const value =
        this.name.length === 0 ||
        this.address1.length === 0 ||
        this.bankAccountNumber.length !== 32;

      return value;
    },
    disableEmailPlusButton() {
      if (this.emails.length === 1) {
        return false;
      }
      return !this.emails[this.emails.length - 1].trim()
    }
  },
  methods: {
    async save() {
      this.processing = true;
      const mails = this.emails.filter(e => e.trim());
      let mailsStr = null;
      if (mails.length !== 0) {
        mailsStr = JSON.stringify(mails);
      }
      const res = await this.store.addOne(mailsStr, this.name, this.address1, this.address2, this.bankAccountNumber, this.nip);
      if (res.status === 409) {
        this.pushError("Podany email jest już przypisany do któregoś dostawcy");
      }
      else if (res.status === 200) {
        document.getElementById(`${this.identifier}-close-btn`).click();
        this.$emit("contarctor-added", {
          id: res.data.id,
          name: this.name,
          address1: this.address1,
          address2: this.address2,
          bankAccountNumber: this.bankAccountNumber,
          nip: this.nip,
          email: JSON.stringify(this.emails)
        });
        this.name = "";
        this.address1 = "";
        this.address2 = "";
        this.bankAccountNumber = "";
        this.nip = "";
        this.emails = [''];

      } else {
        this.pushError("Nieznany błąd")
      }
      this.processing = false;
    },

  }
};
</script>
  
