import { getToken, serverUrl, postAuthorizedConfig, getConfig } from "@/rest/token";

export async function updatePeriodic(id, contractorId, name, checkout) {                          //updateServer
    const response = await fetch(`${serverUrl}/periodic`, {
        method: 'PUT',
        headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${getToken()}`
        },
        body: JSON.stringify({ id, contractorId, name, checkout })
    });
    return response.status;
}

export async function savePeriodicToServer(contractorId, dateStarts, repeats, name, checkout) {     //serverSave
    const response = await fetch(`${serverUrl}/periodic`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "authorization": `Bearer ${getToken()}`
        },
        body: JSON.stringify({ contractorId, dateStarts, repeats, name, checkout })
    });
    return response.status;
}

export async function deletePeriodic(id, date, type) {                                                //deleteServer
    const response = await fetch(`${serverUrl}/periodic/${id}?type=${type}&date=${date}`, {
        method: "DELETE",
        headers: {
            authorization: `Bearer ${getToken()}`
        }
    });
    return response.status;
}

export async function UpdatePeriodicInstance(data) {                                               //UpdateInstanceServer
    const response = await fetch(`${serverUrl}/periodic-instance`, {
        method: 'PUT',
        headers: {
            "authorization": `Bearer ${getToken()}`
        },
        body: data
    });
    return response;
}

export async function DeleteFileFromPeriodic(parentId, date) {                                     //DeleteFile
    const response = await fetch(`${serverUrl}/periodic-instance/delete-file`, {
        ...postAuthorizedConfig(),
        body: JSON.stringify({ parentId, date })
    });
    return response.status;
}


export async function FetchPeriodics(year, month) {
    let response;
    try {
        response = await fetch(`${serverUrl}/periodic?month=${month}&year=${year}`, getConfig());
        if (response.status == 200) {
            const text = await response.text();
            return JSON.parse(text);
        }

    } catch (error) {
        return response.status;
    }
}