
export const VALIDATE_EMAIL_MIXIN = {

    methods: {
        validateEmail(email) {
            const regex = /^\S+[@]{1}\S+\.\S+$/;
            return regex.test(email);
        }
    }

}