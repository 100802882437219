import { serverUrl, postConfig, getToken } from "@/rest/token";

export const AUTH_BRIDGE_MIXIN = {

    methods: {

        async registerUser(data) {
            return fetch(`${serverUrl}/auth/register`, {
                method: "POST",
                headers: {
                    authorization: `Bearer ${getToken()}`
                },
                body: data
            });
        },

        async loginUser(username, password) {
            const response = await fetch(`${serverUrl}/auth/login`, {
                ...postConfig(),
                body: JSON.stringify({ username, password })
            });

            if (response.status == 200) {
                const text = await response.text();
                const data = JSON.parse(text);
                return data;
            }
            return false;
        },

        async DoesUserExist(email) {
            const response = await fetch(`${serverUrl}/auth/indicator`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email })
            });
            if (response.status !== 200) {
                this.pushError("Nieznany błąd");
                return;
            }
            const text = await response.text();
            return text === 'true';
        }

    }
}