import { createApp, ref } from 'vue'
import App from './App.vue'
import router from './router'
import LoadScript from "vue-plugin-load-script";
import VueTheMask from 'vue-the-mask'

import "./Theme.scss"
import "bootstrap"

import { GLOBAL_MIXIN } from './mixins/globalMinxin';
import { MESSAGE_STACK } from './components/messageStack/messageStackMixin';
import { createPinia } from 'pinia';

const GLOBALS = ref({
    uiMessages: []
});


createApp(App)
    .use(router)
    .use(LoadScript)
    .use(VueTheMask)
    .use(createPinia())
    .provide('GLOBALS', GLOBALS)
    .mixin(GLOBAL_MIXIN)
    .mixin(MESSAGE_STACK)
    .mount('#app');
