<template>
  <MonthPicker></MonthPicker>

  <div class="container mb-2">
    <div class="d-flex justify-content-center mt-3">
      <div class="btn-group" role="group" aria-label="Basic example">

        <button v-for="(text, index) in this.filters" :key="text" @click="this.activeFilterIndex = index"
          :class="[index === this.activeFilterIndex ? 'text-primary' : 'text-secondary']"
          class="btn d-flex align-items-center">{{
            text }}</button>


      </div>
    </div>
  </div>
  <div class="container d-flex justify-content-center my-5" v-if="store.showLoader" style="height: 58vh;">
    <LoadingSpinner class="align-self-center"></LoadingSpinner>
  </div>

  <main class="container" v-else>

    <div class="list-container">
      <div v-if="displayAllPaidImage" class="flex-column d-flex justify-content-center">
        <h1 class="display-6 d-flex justify-content-center mt-3 text-center text-secondary">Wszystko opłacone
        </h1>
        <img src="@/assets/AllPaid.png" class="mt-3">
      </div>


      <div v-if="displayNoInvoicesImage" class="flex-column d-flex justify-content-center">
        <h1 class="display-6 d-flex justify-content-center mt-3 text-center text-secondary">Czekamy na Twoje dokumenty
        </h1>
        <img src="@/assets/ListeningToFeedback.png" class="mt-3">
      </div>

      <div style="display: contents;">

        <component v-for="(item, index) in displayItemsList" :key="index + item.date + item.id + item.type + item.storeId"
          :storeId="item.storeId" :is="determineListingEntryComponentType(item.type)" :uuid="index" :associatedData="item"
          :newlyAddedContractor="newlyAddedContractor" @show-message-modal="val => activeMessageData = val">
        </component>
      </div>

      <div class="footer_list d-flex mt-4 justify-content-end" v-if="displayItemsList.length !== 0">
        <div class="flex-column">
          <div class="total d-flex justify-content-end">
            Razem: {{ totalCheckout }} <small class="ps-1 text-secondary">PLN</small>
          </div>
        </div>
      </div>
    </div>
  </main>

  <PreviewMessage identifier="messagePreviewModal" :messageData="activeMessageData"></PreviewMessage>

  <BottomActionsModal></BottomActionsModal>
  <AddContractorModal identifier="modalAddContractor" @contarctor-added="val => this.newlyAddedContractor = val"
    @contractorModalClosed="contractorModalClosed">
  </AddContractorModal>

  <!-- Modal -->
  <AddCostModal identifier="cost1" :newlyAddedContractor="newlyAddedContractor" ref="addCostModal"></AddCostModal>
  <AddDocumentModal identifier="document1" :isScan="false"></AddDocumentModal>
  <AddDocumentModal identifier="scan1" :isScan="true" ref="scanModal"></AddDocumentModal>

  <footer class="footer mt-auto py-3 bg-body-tertiary fixed-bottom z-5">
    <div class="container">
      <div class="list-container">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <p class="mb-0">Dodaj koszt</p>
          </div>
          <div class="d-flex flex-row">
            <button type="button" class="btn border btn-sm d-flex align-items-center rounded-pill ms-3"
              data-bs-toggle="modal" data-bs-target="#cost1">
              <span class="material-symbols-outlined c2">avg_pace</span>
            </button>
            <button type="button" class="btn border btn-sm d-flex align-items-center rounded-pill ms-3"
              @click="scanClick">
              <span class="material-symbols-outlined c3">scan</span>
            </button>
            <button type="button" class="btn border btn-sm d-flex align-items-center rounded-pill ms-3"
              data-bs-toggle="modal" data-bs-target="#document1">
              <span class="material-symbols-outlined c4">draft</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import MessageListingItem from "@/components/landing/listing/message.vue";
import PeriodicListingItem from "@/components/landing/listing/periodical.vue";
import UploadListingItem from "@/components/landing/listing/upload.vue";
import AddCostModal from "@/components/landing/modals/addCost.vue";
import AddDocumentModal from "@/components/landing/modals/addDocument.vue"
import MonthPicker from "@/components/landing/monthPicker.vue";
import BottomActionsModal from "@/components/landing/listing/bottomActionsModal.vue";
import AddContractorModal from "@/components/contractors/addContractorModal.vue"
import PreviewMessage from "@/components/landing/modals/previewMessage.vue";
import LoadingSpinner from "@/components/landing/listing/spinner.vue"
import { useSelectedDateStore } from '@/stores/date'
import { useListingStore } from "@/stores/listing-store";
import { storeToRefs } from "pinia"
export default {
  mixins: [],
  name: "testPage",
  components: {
    MessageListingItem,
    PeriodicListingItem,
    UploadListingItem,
    AddCostModal,
    AddDocumentModal,
    MonthPicker,
    BottomActionsModal,
    AddContractorModal,
    PreviewMessage,
    LoadingSpinner
  },
  setup() {
    const dateStore = useSelectedDateStore();
    const listingStore = useListingStore();
    const { month, year } = storeToRefs(dateStore);
    const { entries, error } = storeToRefs(listingStore);
    return { month, year, listItems: entries, store: listingStore, dateStore, invoicesErrorCode: error }
  },

  data() {
    return {
      filters: ['Wszystkie', 'Nieopłacone', 'Opłacone',],
      activeFilterIndex: 0,
      newlyAddedContractor: null,
      activeMessageData: null
    }
  },
  mounted() {
    this.dateStore.reset();
  },
  watch: {
    invoicesErrorCode(code) {
      if (code) {
        this.pushInfo("Nie udało się pobrać emaili, sprawdź ustawienia skrzynki.");
      }
    }
  },
  methods: {

    contractorModalClosed() {
      if (sessionStorage.getItem('contractorCalledFrom')) {
        if (sessionStorage.getItem('contractorCalledFrom') == 'costModal') {
          this.$refs.addCostModal.open();
        }
        sessionStorage.removeItem('contractorCalledFrom');
      }
    },
    scanClick() {
      this.$refs.scanModal.startCamera();
    },
    determineListingEntryComponentType(typeStr) {

      if (typeStr === 'invoice') {
        return 'MessageListingItem';
      }
      if (typeStr === 'scan') {
        return 'UploadListingItem';
      }
      if (typeStr === 'document') {
        return 'UploadListingItem';
      }
      if (typeStr === 'periodic') {
        return 'PeriodicListingItem';
      }

      return 'ERROR';
    },

  },
  computed: {
    totalCheckout() {
      return this.displayItemsList.reduce((accumulator, item) => {
        if (item.checkout) {
          return accumulator + parseFloat(item.checkout)
        } else {
          return accumulator;
        }
      }, 0).toFixed(2);
    },
    displayAllPaidImage() {
      if (!this.store.showLoader && this.filters[this.activeFilterIndex] === "Nieopłacone") {
        return this.filteredList.length === 0 && this.listItems.length !== 0;
      }
      return false;
    },
    displayNoInvoicesImage() {
      if (!this.store.showLoader) {
        return this.displayAllPaidImage == false && this.filteredList.length === 0;
      }
      return false;
    },

    displayItemsList() {
      return this.filteredList.slice(0).sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
      })
    },

    filteredList() {
      if (!this.listItems) {
        return [];
      }
      const filter = this.filters[this.activeFilterIndex];

      if (filter === "Wszystkie") {
        return this.listItems;
      }
      if (filter === "Nieopłacone") {
        return this.listItems.filter(i => i.isPaid === false)
      }
      if (filter === "Opłacone") {
        return this.listItems.filter(i => i.isPaid === true)
      }
      return this.listItems;
    }

  }

};
</script>

<style>
.footer_list {
  padding-bottom: 100px;
}

.c1 {
  --bs-text-opacity: 1;
  color: #ff6e6e !important;
}

.c2 {
  --bs-text-opacity: 1;
  color: #b6b6b6 !important;
}

.c3 {
  --bs-text-opacity: 1;
  color: #ffd66e !important;
}

.c4 {
  --bs-text-opacity: 1;
  color: #4096ff !important;
}

.cpaid {
  --bs-text-opacity: 1;
  color: #7fc146 !important;
}

.expand-listing-item-trigger {
  cursor: pointer;
}
</style>
