<template>
  <div class="container" ref="containerRef">
    <nav class="navbar" aria-label="Oo">
      <div class="container-fluid">

        <div class="navbar-brand link make-pointer" @click="titleClicked()">
          <img :src="darkMode ? 'Logo_costlinker_dark.png' : 'Logo_costlinker.png'" alt="Costlinker">
        </div>
        <div class="d-flex align-items-center " :class="{ csHidden: !showBurgerMenu }">
          <StorageIndicator :percentage="storageOccupiedPercentage"></StorageIndicator>
          <button class="profile my-round border ms-3" type="button" ref="logoBtn" @click="collapse.toggle()"></button>
        </div>
        <div class="navbar-collapse collapse" :class="{ csHidden: !showBurgerMenu }" ref="myCollapse" id="myCollapse">
          <ul class="navbar-nav me-auto mb-2">
            <li class="nav-item" @click="collapse.hide()">
              <router-link class="nav-link d-flex justify-content-end" to="/contractors">
                Lista dostawców
                <span class="material-symbols-outlined ms-2">groups</span>
              </router-link>
            </li>
            <li class="nav-item" @click="collapse.hide()">
              <router-link class="nav-link d-flex justify-content-end" to="/settings">
                Ustawienia
                <span class="material-symbols-outlined ms-2">settings</span>
              </router-link>
            </li>
            <li class="nav-item" v-if="userStore.isAdmin" @click="collapse.hide()">
              <router-link class="nav-link d-flex justify-content-end" to="/admin">
                Administracja
                <span class="material-symbols-outlined ms-2">admin_panel_settings</span>
              </router-link>
            </li>
            <li class="nav-item make-pointer">
              <a class="nav-link d-flex justify-content-end" @click="logout">Wyloguj się <span
                  class="material-symbols-outlined ms-2">logout</span>
              </a>
            </li>

            <li class="form-check form-switch ms-auto mt-3 me-3" @click="togglerClick">
              <label class="form-check-label ms-3 make-pointer" for="lightSwitch">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                  class="bi bi-brightness-high" viewBox="0 0 16 16">
                  <path
                    d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z" />
                </svg>
              </label>
              <input class="form-check-input make-pointer" type="checkbox" id="lightSwitch" v-model="this.darkMode" />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { dropToken } from '@/rest/token';
import { onClickOutside } from '@vueuse/core'
import { ref, computed } from 'vue';
import { Collapse } from 'bootstrap';
import { useUserDataStore } from "@/stores/user"
import { storeToRefs } from "pinia"
import { useSelectedDateStore } from '@/stores/date'
import { useListingStore } from '@/stores/listing-store';
import { useStorageSizeStore } from '@/stores/storage-size';
import StorageIndicator from './StorageIndicator.vue';
export default {
  name: "TopBar",
  data() {
    return {
      darkMode: null,
      collapse: null,
      logo_base64: "",
    };
  },
  setup() {
    const containerRef = ref(null);
    const userStore = useUserDataStore();
    const dateStore = useSelectedDateStore();
    const listingStore = useListingStore();
    const storageStore = useStorageSizeStore();

    const userLogged = computed(() => Boolean(userStore.user_name));
    return { containerRef, userStore, dateStore, listingStore, storageStore, userLogged };
  },
  mounted() {
    this.collapse = new Collapse(this.$refs.myCollapse, { toggle: false });
    onClickOutside(this.containerRef, () => { this.collapse.hide(); });
    this.logo_base64 = storeToRefs(this.userStore).logo_base64;
    this.darkMode = (localStorage.getItem('theme') == 'dark');
  },
  methods: {
    logout() {
      dropToken();
      this.userStore.onLogout();
      this.dateStore.reset();
      this.listingStore.dropAll();
      this.$router.push({ name: 'login' });
    },
    togglerClick() {
      setTimeout(() => { this.collapse.hide(); }, 350);
    },
    titleClicked() {
      this.collapse.hide();
      this.dateStore.reset();

      if (this.userLogged) {
        this.$router.push({ name: 'home' });
      } else {
        window.location = "http://costlinker.com"
      }
    }
  },
  watch: {
    darkMode(value) {
      const theme = value ? 'dark' : 'light';
      document.documentElement.setAttribute('data-bs-theme', theme);
      localStorage.setItem('theme', theme);
    },
    logo_base64() {
      if (this.logo_base64) {
        let img = new Image();
        img.src = `data:image/png;base64,${this.logo_base64}`;
        this.$refs.logoBtn.style["background-image"] = "url('" + img.src + "')";
      }
    },
  },
  computed: {
    showBurgerMenu() {
      const noTopbarRoutes = ['login', 'setup1', 'setup2', 'setup3', 'resetpassword'];
      return !noTopbarRoutes.includes(this.$route.name);
    },
    storageOccupiedPercentage() {
      const occupied = this.storageStore.bytes;
      const availble = this.userStore.storage_limit_mb * 1_000_000;
      return Math.round(occupied / availble * 100);
    }
  },
  components: { StorageIndicator }
};
</script>

<style scoped>
.make-pointer:hover {
  cursor: pointer;
}

.profile {
  background-image: url("@/assets/avatar.png");
  background-size: cover;
  background-color: transparent;
  border-radius: 100px;
  width: 36px;
  height: 36px;
  transition: 0.5s;
}

.profile:hover {
  background-color: #ffffff;
  border-radius: 100px;
  transition: 0.5s;
}

.csHidden {
  display: none !important;
}
</style>
