import { v4 as uuidv4 } from 'uuid';
export const MESSAGE_STACK = {

    inject: ['GLOBALS'],

    methods: {

        pushInfo(text, title = "Informacja", delay = 0) {
            const uuid = uuidv4();
            this.GLOBALS.uiMessages.push(
                {
                    uuid,
                    type: "info",
                    text, title, delay
                })
            return uuid;
        },

        pushError(text, title = "Błąd", delay = 0) {
            const uuid = uuidv4();
            this.GLOBALS.uiMessages.push(
                {
                    uuid,
                    type: "error",
                    text, title, delay
                })
            return uuid;
        },

        pop(uuid) {
            const index = this.GLOBALS.uiMessages.findIndex(i => i.uuid === uuid);//.delete(uuid);
            this.GLOBALS.uiMessages.splice(index, 1);
        }
    }

}