<template>
    <div style="display: contents;">


        <button v-if="!isPaid" type="button" class="btn btn-outline-primary w-100" autocomplete="off"
            :disabled="!canPay">Nie
            zapłacono</button>

        <button v-if="isPaid" type="button" class="btn btn-success w-100" autocomplete="off">Zapłacono</button>
    </div>
</template>


<script>

export default {
    name: "PayButton",
    props: {
        isPaid: Boolean,
        canPay: { type: Boolean, default: true }
    }

}
</script>