<template>
    <div class="container">
        <div class="d-flex justify-content-between mt-1">
            <div class="d-flex align-items-center">
                <button class="btn btn-sm d-flex align-items-center rounded-pill pe-2" type="button" @click="previous">
                    <span class="material-symbols-outlined">arrow_back</span>
                </button>
                <div class="text-primary px-2">
                    {{ monthNames[month] }}
                    <sup class="text-secondary">
                        <a v-if="!pickerActive" @click="pickerActive = !pickerActive" id="year">
                            {{ year }}
                        </a>
                    </sup>
                    <a v-if="pickerActive">
                        <input v-model="year" type="number" min="1990" :max="maxYear" step="1" />
                        <span class="material-symbols-outlined text-success" @click="pickerActive = false">close</span>
                    </a>
                </div>
                <button class="btn btn-sm d-flex align-items-center rounded-pill" type="button" data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom">
                    <span class="material-symbols-outlined">more_horiz</span>
                </button>
            </div>

            <div class="d-flex align-items-center" v-if="showNextBtn">
                <div class="px-2 text-secondary">{{ monthNames[(month + 1) % 12] }}</div>
                <button class="btn btn-sm d-flex align-items-center rounded-pill pe-2" type="button" @click="next">
                    <span class="material-symbols-outlined">arrow_forward</span>
                </button>
            </div>
        </div>
    </div>
</template>



<script>
import { MONTH_NAMES_MIXIN } from '@/mixins/monthNames';
import { useSelectedDateStore } from '@/stores/date'
import { storeToRefs } from "pinia"
export default {
    name: "MonthPickingBar",
    mixins: [MONTH_NAMES_MIXIN],
    setup() {
        const dateStore = useSelectedDateStore();
        const { month, year } = storeToRefs(dateStore);

        return { dateStore, month: month, storedYear: year }
    },

    data() {
        return {
            pickerActive: false,
            maxYear: new Date().getUTCFullYear() + 1,
            year: this.dateStore.year, // intenctionaly mace poperty unreactive
        }
    },
    methods: {
        next() {
            if (this.month < 11) {
                this.month++;
            } else {
                this.month = 0;
                this.year++;
                this.dateStore.year = this.year;
            }

        },
        previous() {
            if (this.month > 0) {
                this.month--;
            } else {
                this.month = 11;
                this.year--;
                this.dateStore.year = this.year;
            }
        }
    },
    watch: {
        year() {
            if (this.year > this.maxYear) {
                this.year = this.maxYear;
            }
        },
        storedYear() {
            this.year = this.storedYear;
        },
        pickerActive() {
            if (this.pickerActive === false) {
                this.dateStore.year = this.year;
            }
        }
    },
    computed: {
        showNextBtn() {
            if (this.month === 11 && this.year === this.maxYear) {
                return false;
            }
            return true;
        },
    }


}
</script>

<style>
#year:hover {
    cursor: pointer;
}
</style>