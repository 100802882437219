<style>
.my-offcanvas {
    min-height: 280px;
}
</style>
<template>
    <div class="offcanvas offcanvas-bottom my-offcanvas" tabindex="-1" id="offcanvasBottom"
        aria-labelledby="offcanvasBottomLabel">
        <div class="container">
            <div class="list-container">
                <div class="offcanvas-header">
                    <h5 class="offcanvas-title" id="offcanvasBottomLabel">
                        Akcje na kosztach z miesiąca
                    </h5>
                    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"
                        aria-label="Close"></button>
                </div>
                <div class="offcanvas-body small">

                    <div class="d-grid">
                        <button class="btn btn-primary d-flex justify-content-center mb-3" type="button" @click="downloadAll"
                            :disabled="disablebuttons">
                            Pobierz wszystkie dokumenty
                            <span class="material-symbols-outlined ms-1">download</span>
                        </button>
                        <a style="display: none;" ref="downloadLink">test</a>

                        <button class="btn btn-primary d-flex justify-content-center mb-1" type="button" @click="sendEmail"
                            :disabled="disablebuttons">
                            Wyślij wszystkie dokumenty
                            <span class="material-symbols-outlined ms-2">forward_to_inbox</span>
                        </button>

                        <div class="d-flex justify-content-between align-items-center mb-1">
                            <small class="text-secondary">
                                Dokumenty wysłane na adres: {{ userStore.send_to_mail || 'nie zapisano adresu do księgowości' }}
                            </small>
                        </div>

                        <button class="btn btn-primary d-flex justify-content-center" type="button" @click="archivize"
                            :disabled="disablebuttons">
                            Archiwizuj pliki z miesiąca
                            <span class="material-symbols-outlined ms-2">inventory_2</span>
                        </button>
                      
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ARCHIVIZER_BRIDGE_MIXIN } from "@/mixins/bridges/archivizer";
import { SENDER_BRIDGE_MIXIN } from "@/mixins/bridges/sender";
import { MONTH_NAMES_MIXIN } from "@/mixins/monthNames";
import { useUserDataStore } from "@/stores/user"
import { useSelectedDateStore } from '@/stores/date'
import { useListingStore } from "@/stores/listing-store";
import { storeToRefs } from "pinia"
export default {
    name: "BottomActionsModal",
    mixins: [ARCHIVIZER_BRIDGE_MIXIN, SENDER_BRIDGE_MIXIN, MONTH_NAMES_MIXIN],
    data() {
        return {
            disablebuttons: false
        }
    },
    setup() {
        const userStore = useUserDataStore();
        const dateStore = useSelectedDateStore();
        const listing = useListingStore();
        const { month, year } = storeToRefs(dateStore);
        return { userStore, month, year, listing };
    },
    methods: {
        async downloadAll() {
            this.disablebuttons = true;
            const fileLink = await this.getArchiveDownloadLink(this.year, this.month);
            if (typeof fileLink === 'number') {
                this.pushError("Nie wybrano żadnych plików");
                this.disablebuttons = false;
                return;
            }
            const a = this.$refs.downloadLink;
            a.setAttribute('href', fileLink);
            a.click();
            this.disablebuttons = false;
        },
        async sendEmail() {
            this.disablebuttons = true;
            const responseCode = await this.requestSendEmails(this.year, this.month);
            if (responseCode === 404) {
                this.pushError("Nie wybrano żadnych plików");
                this.disablebuttons = false;
                return;
            }
            if (responseCode !== 200) {
                this.pushError("Nie udało się wysłać wiadomości, sprawdź swoje ustawienia SMTP", "Błąd przy wysyłaniu dokumentów");
                this.disablebuttons = false;
                return;
            }
            this.pushInfo("Wiadomośc została wysłana", "Ok");
            this.disablebuttons = false;
        },
        async archivize() {
            this.disablebuttons = true;
            const response = await this.sendArchivizeRequest(this.year, this.month);
            if (response.status === 200) {
                this.listing.reload();
                this.pushInfo(`Pliki z danego miesiąca ${this.monthNames[this.month]} zostały zarchiwizowane i przesłane na email: ${this.userStore.user_name}`, "Sukces");
            } else {
                const code = JSON.parse(await response.text()).errorcode;
                if (code == 888) {
                    this.pushError("Nie wybrano żadnych plików");
                } else {
                    this.pushError("Nieznany błąd");
                }
            }
            this.disablebuttons = false;
        }
    }

}
</script>