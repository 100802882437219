<template>
    <div class="modal fade" :id="this.identifier" tabindex="-1" :aria-labelledby="this.identifier" aria-hidden="true">
        <div class="modal-dialog modal-fullscreen-sm-down">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" :id="this.identifier">Podgląd wiadomości</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">

                    <p class="fs-5 text-secondary">Temat: {{ fullMessageData?.subject }} </p>
                    <p class="fs-6 text-secondary">Od: {{ sentFromEmail }} </p>

                    <form class="row g-3">
                        <textarea name="" id="" cols="30" rows="10" disabled="true" class="form-control" v-model="content"
                            placeholder="Wiadomość nie zawiera treści">
                        </textarea>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        Zamknij
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { MESSAGE_BRIDGE_MIXIN } from '@/mixins/bridges/message';

export default {
    mixins: [MESSAGE_BRIDGE_MIXIN],
    name: "PreviewMessageModal",
    props: {
        identifier: String,
        messageData: Object
    },
    data() {
        return {
            fullMessageData: {},
            content: ''
        }
    },
    methods: {

    },
    watch: {
        async messageData(msg) {
            const data = await this.FetchMessage(msg?.id);
            if (typeof data == "number") {
                this.pushError("Nieznany błąd");
                return;
            }
            this.fullMessageData = data;
            this.content = '';
            if (this.fullMessageData?.content.trim().length !== 0) {
                this.content = this.fullMessageData.content;
            }
        }
    },
    computed: {
        sentFromEmail() {
            if ("from" in this.fullMessageData) {

                const from = this.fullMessageData.from;
                const expr = /<[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)>*$/
                return from.replace(from.replace(expr, ""), '').replace(/[<>]/g, '').trim();
            }
            return "";
        },

    }
};
</script>
  